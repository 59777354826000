import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../../components/Layout"
import Heading from "../../components/Layout/Heading"
import SEO from "../../components/SEO"
import SubHeading from "../../components/Layout/SubHeading"
import Icon from "../../components/Icon"

import southNowra from "../../images/dealerships/solomon-motors-city.jpg"
import midCity from "../../images/dealerships/solomons-mid-city-autos.jpg"

import "./index.scss"

const UsedVehicles = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Used Vehicles"
      description="Solomon Motor Group is the Number 1 Used Car Dealership Network in Nowra, Shoalhaven in two great locations on the Princes Highway"
    />
    <Heading title="Used Vehicles" />
    <SubHeading subHeading="Select a Dealership to view stock" />
    <div className="row">
      <div className="col-12 col-md-6 text-center dealer-container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 dealer-name-container">
            <Link
              to="/used-vehicles/solomon-motors-city"
              className="dealer-name"
            >
              <img
                src={southNowra}
                className="dealer-image"
                alt="Solomon Motors City"
              />
              <br />
              Solomon Motors City
            </Link>
          </div>
        </div>
        <Link to="/used-vehicles/solomon-motors-city">
          <Button variant="primary" bsPrefix="smg">
            View Stock <Icon prefix="fas" name="arrow-circle-right" />
          </Button>
        </Link>
      </div>
      <div className="col-12 col-md-6 text-center dealer-container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 dealer-name-container">
            <Link
              to="/used-vehicles/solomons-mid-city-autos"
              className="dealer-name"
            >
              <img
                src={midCity}
                className="dealer-image"
                alt="Solomons Mid City Autos"
              />
              <br />
              Solomons Mid City Autos
            </Link>
          </div>
        </div>
        <Link to="/used-vehicles/solomons-mid-city-autos">
          <Button variant="primary" bsPrefix="smg">
            View Stock <Icon prefix="fas" name="arrow-circle-right" />
          </Button>
        </Link>
      </div>
    </div>
  </Layout>
)

export default UsedVehicles
